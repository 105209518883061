import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { useNotificationContext } from '../context/useNotification'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CustomSnackbar = () => {
  const { notification, onCloseNotification } = useNotificationContext()

  const severity = notification.error ? 'error' : 'info'

  return (
    <Snackbar
      autoHideDuration={4000}
      onClose={onCloseNotification}
      open={notification.show}
    >
      <Alert
        severity={notification.severity || severity}
        onClose={onCloseNotification}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar
