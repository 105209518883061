/*global firebase*/

import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import firebase from 'firebase'

import theme from '../resources/theme'
import logo from '../resources/images/logo_completo.svg'
import dotsImage from '../resources/images/puntos5x5_blancos.svg'
import footerMessages from '../resources/messages'
import colors from '../resources/colors'

import facebookLogo from '../resources/images/fb_icon.svg'
import twitterLogo from '../resources/images/twitter_icon.svg'
import youtubeLogo from '../resources/images/youtube_icon.svg'
import whatsappLogo from '../resources/images/whatsapp_icon.svg'
import gmailLogo from '../resources/images/gmail_icon.svg'
import phoneLogo from '../resources/images/phone_icon.svg'

const messages = footerMessages.footer

const Container = styled.div`
  background: ${colors.blueLightest};
  padding: 24px 0;
  position: relative;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 14em 0 14em;
  ${theme.breakpoints.down('lg')} {
    margin: 0 9em 0 9em;
  }
  ${theme.breakpoints.only('xs')} {
    margin: 0 auto 0 auto;
    justify-content: center;
  }
  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }
`

const SocialLogosContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 620px) {
    justify-content: center;
    margin-top: 24px;
  }
`

const StyledImg = styled.img`
  margin-left: 28px;
  width: 28px;
  &:hover {
    cursor: pointer;
  }
  ${theme.breakpoints.down('md')} {
    margin-left: 18px;
    width: 18px;
  }
`

const StyledImgContact = styled.img`
  margin-right: 8px;
  width: 18px;
  &:hover {
    cursor: pointer;
  }
  ${theme.breakpoints.down('md')} {
    width: 14px;
  }
`

const Contact = styled.div`
  margin: 6px 0 8px 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 620px) {
    align-items: center;
    justify-content: center;
  }
`

const ContactNumber = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 620px) {
    align-items: center;
    justify-content: center;
  }
`

const AnchorElement = styled.a`
  @media (max-width: 620px) {
    display: flex;
    align-items: center;
  }
`

const LogoImage = styled.img`
  height: 80px;
  margin-bottom: 16px;
  align-self: flex-start;
  @media (max-width: 620px) {
    align-self: center;
    margin-bottom: 4px;
  }
`

const Dots = styled(Grid)`
  position: absolute;
  left: 0;
  bottom: 0;
`

const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 620px) {
    justify-content: center;
  }
`

const We = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 620px) {
    align-items: center;
    margin: 24px 0;
  }
`

const WeLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Bottom = styled.div`
  @media (max-width: 620px) {
    margin-bottom: 16px;
  }
`

const useStyles = makeStyles({
  contactText: {
    lineHeight: '28px',
  },
  weText: {
    fontWeight: 'bold',
  },
})

const FooterSocial = () => {
  const [generalCounter, setGeneralCounter] = React.useState(0)

  const getGeneralCounterData = async () => {
    const generalCounterRef = firebase
      .firestore()
      .collection('statics')
      .doc('downloadsCount')
    const generalCounterDoc = await generalCounterRef.get()
    const { count } = generalCounterDoc.data()
    setGeneralCounter(count)
  }

  React.useEffect(() => {
    getGeneralCounterData()
  }, [])

  const handleClick = (evt) => {
    const {
      target: { id },
    } = evt
    switch (id) {
      case 'facebook':
        window.location.href =
          'https://www.facebook.com/Statu-Quo-Advance-214963115846046/'
        break
      case 'twitter':
        window.location.href = 'https://twitter.com/statuquoadvance'
        break
      case 'youtube':
        window.location.href =
          'https://www.youtube.com/channel/UCLBgcIUW5LIerJdlZTZl-hw'
        break
      case 'whatsapp':
        window.location.href = 'https://wa.me/5216671804167'
        break
      default:
        break
    }
  }

  const isMobile = useMediaQuery({
    query: '(max-width: 620px)',
  })

  const copyrightComponent = (
    <>
      <Typography variant="body1" align={isMobile ? 'center' : 'inherit'}>
        {messages.copyright}
      </Typography>
      {generalCounter ? (
        <Typography variant="body1">
          {`${generalCounter.toLocaleString()} ${messages.descargas} `}
        </Typography>
      ) : null}
    </>
  )

  const classes = useStyles()

  return (
    <Container>
      <Content>
        <Top>
          <LogoImage src={logo} alt="logo" />
          {!isMobile && copyrightComponent}
        </Top>
        {/* <We>
          <Typography
            variant="body1"
            className={classes.weText}
            paragraph={!isMobile}
          >
            {messages.nosotros}
          </Typography>
          <WeLink href="/mision_vision">
            <Typography variant="body1" className={classes.contactText}>
              {messages.misionVision}
            </Typography>
          </WeLink>
          <WeLink href="/servicios">
            <Typography variant="body1" className={classes.contactText}>
              {messages.queOfrecemos}
            </Typography>
          </WeLink>
          <WeLink href="/valores">
            <Typography variant="body1" className={classes.contactText}>
              {messages.valores}
            </Typography>
          </WeLink>
        </We> */}
        <Bottom>
          <SocialLogosContainer>
            <StyledImg
              id="facebook"
              alt="facebook"
              src={facebookLogo}
              onClick={handleClick}
            />
            <StyledImg
              id="twitter"
              alt="twitter"
              src={twitterLogo}
              onClick={handleClick}
            />
            <StyledImg
              id="youtube"
              alt="youtube"
              src={youtubeLogo}
              onClick={handleClick}
            />
            <AnchorElement href="mailto:statuquoadvance@gmail.com">
              <StyledImg id="gmail" alt="gmail" src={gmailLogo} />
            </AnchorElement>
          </SocialLogosContainer>
          <Contact>
            <Typography
              variant="body1"
              align="right"
              className={classes.contactText}
            >
              {messages.contratacionesAl}
            </Typography>
            <ContactNumber>
              <StyledImgContact
                id="whatsapp"
                alt="whatsapp"
                src={whatsappLogo}
                onClick={handleClick}
              />
              <StyledImgContact src={phoneLogo} onClick={handleClick} />
              <Typography variant="body1" className={classes.contactText}>
                {messages.contratacionesTel}
              </Typography>
            </ContactNumber>
          </Contact>
        </Bottom>
        <MediaQuery maxDeviceWidth={620}>{copyrightComponent}</MediaQuery>
      </Content>
      <Dots container>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <img style={{ width: '100%' }} src={dotsImage} alt="dots-bottom" />
        </Grid>
      </Dots>
    </Container>
  )
}

export default FooterSocial
