/*global firebase*/

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import firebase from 'firebase'

import { downloadFile } from '../utils'
import downloadIcon from '../resources/images/download-icon.svg'
import { spanish } from '../utils/moment'
import theme from '../resources/theme'
import sqMessages from '../resources/messages'
const messages = sqMessages.downloadItem

moment.locale('es', spanish)

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(),
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 280,
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  bold: {
    fontWeight: 700,
  },
})

const handleOnDownload = async (fileId) => {
  const db = firebase.firestore()
  const fileRef = db.collection('files').doc(fileId)
  const generalCounterRef = db.collection('statics').doc('downloadsCount')

  try {
    const fileDoc = await fileRef.get()
    const generalCounterDoc = await generalCounterRef.get()
    if (fileDoc.exists) {
      const { url, counter, fileName } = fileDoc.data()
      const { count: generalCounter } = generalCounterDoc.data()
      downloadFile(url, fileName)
      fileRef.set(
        {
          counter: counter + 1,
        },
        { merge: true }
      )
      generalCounterRef.set(
        {
          count: generalCounter + 1,
        },
        { merge: true }
      )
    }
  } catch (e) {
    // const { showNotificationAction } = this.props
    // showNotificationAction(e.message || 'error', true)
  }
}

const DownloadItem = ({ fileId, title, availableAt, isSingleItem }) => {
  const classes = useStyles()

  const enabled = moment().isAfter(moment(availableAt)) || isSingleItem
  return (
    <Grid
      item
      align="center"
      key={fileId}
      lg={isSingleItem ? 12 : 6}
      md={12}
      sm={isSingleItem ? 12 : 6}
      xs={12}
    >
      <Button
        disabled={!enabled}
        className={classes.button}
        size="large"
        onClick={(_) => handleOnDownload(fileId)}
      >
        <div className={classes.buttonContent}>
          <Typography variant="body1" className={classes.bold}>
            {isSingleItem
              ? messages.descargarEncuesta
              : `${messages.descargar} ${title}`}
          </Typography>
          <img src={downloadIcon} className={classes.rightIcon} />
        </div>
      </Button>
      {!isSingleItem && (
        <Typography variant="body2" paragraph>
          {`${messages.disponible} ${moment(availableAt).format(
            'DD MMMM YYYY h:mm a'
          )}`}
        </Typography>
      )}
    </Grid>
  )
}

export default DownloadItem
