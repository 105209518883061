import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'

import theme from '../resources/theme'
import Logo from '../resources/images/logo.svg'
import headerMessages from '../resources/messages'
import MenuMobile from './MenuMobile'

const messages = headerMessages.header

const Img = styled.img`
  cursor: pointer;
  height: 80px;
  ${theme.breakpoints.only('lg')} {
    height: 72px;
  }
  ${theme.breakpoints.down('md')} {
    height: 64px;
  }
  ${theme.breakpoints.only('xs')} {
    height: 60px;
  }
  @media (max-width: 425px) {
    height: 48px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 36px 10.5em 0 10.5em;
  ${theme.breakpoints.down('lg')} {
    margin: 36px 5em 0 5em;
  }
  ${theme.breakpoints.only('xs')} {
    margin: 24px auto 0 auto;
    justify-content: center;
  }
`

const AppBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderButton = styled(Button)`
  margin: 0 8px !important;
`

const Header = ({ history: { push } }) => {
  const handleOnClick = (route) => () => {
    goTo(route)
  }

  const goTo = (route) => {
    push(route)
  }

  return (
    <Container>
      <Img src={Logo} alt="logo" onClick={handleOnClick('/')} />
      <AppBarContainer>
        <MediaQuery minDeviceWidth={680}>
          {(matches) => {
            if (matches) {
              return (
                <Toolbar variant="dense">
                  <HeaderButton onClick={handleOnClick('/')}>
                    {messages.inicio}
                  </HeaderButton>
                  <HeaderButton onClick={handleOnClick('/encuestas')}>
                    {messages.encuestas}
                  </HeaderButton>
                  <HeaderButton onClick={handleOnClick('/nosotros')}>
                    {messages.nosotros}
                  </HeaderButton>
                  {/* <HeaderButton onClick={handleOnClick('/contacto')}>
                    {messages.contacto}
                  </HeaderButton> */}
                </Toolbar>
              )
            }
            return (
              <MenuMobile>
                <Button onClick={handleOnClick('/')}>{messages.inicio}</Button>
                <Button onClick={handleOnClick('/encuestas')}>
                  {messages.encuestas}
                </Button>
                <Button onClick={handleOnClick('/nosotros')}>
                  {messages.nosotros}
                </Button>
              </MenuMobile>
            )
          }}
        </MediaQuery>
      </AppBarContainer>
    </Container>
  )
}

export default Header
