/*global firebase*/

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery } from 'react-responsive'
import firebase from 'firebase'

import { useNotificationContext } from '../context/useNotification'
import AppLayout from '../components/AppLayout'
import PollCard from '../components/PollCard'
import theme from '../resources/theme'

import pollsImage from '../resources/images/pollsImage.svg'
import messages from '../resources/messages'
const pollsMessages = messages.polls

const Container = styled.div`
  margin: 0 0 136px;
`

const PollImage = styled.img`
  width: 100%;
`

const TitleTxt = styled.div`
  font-weight: 600;
  line-height 180px;
  font-size: 144px;
  color: #1F1534;

  ${theme.breakpoints.down('lg')} {
    line-height 130px;
    font-size: 110px;
  }
  
  ${theme.breakpoints.down('md')} {
    align-self: center;
    line-height 110px;
    font-size: 80px;
  }
  
  ${theme.breakpoints.down('sm')} {
    align-self: center;
    line-height 100px;
    font-size: 70px;
  }
  
  ${theme.breakpoints.down('xs')} {
    align-self: center;
    line-height 60px;
    font-size: 50px;
  }
`

const PollsContainer = styled.div`
  padding: 0 3%;
  ${theme.breakpoints.only('xs')} {
    padding: 0 20%;
  }
  @media all and (max-width: 425px) {
    padding: 0 10%;
  }
`

function Polls({ history }) {
  const [polls, setPolls] = useState([])
  const { showNotification } = useNotificationContext()

  const getPolls = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection('polls')
        .where('active', '==', true)
        .get()
      const pollsData = snapshot.docs
        .map((doc) => {
          const { title, subtitle, imageUrl, order, createdAt } = doc.data()
          const { id } = doc
          return { title, subtitle, id, img: imageUrl, order, createdAt }
        })
        .sort((a, b) => (a.order > b.order ? -1 : 1))
      const mod = pollsData.length % 3
      if (mod > 0) {
        const numOfPlaceholders = mod === 1 ? 2 : 1
        for (let i = 0; i < numOfPlaceholders; i++) {
          pollsData.push({ isPlaceholder: true })
        }
      }
      setPolls(pollsData)
    } catch (e) {
      console.error(e)
      showNotification(pollsMessages.errorObtenerEncuestas, true)
    }
  }

  useEffect(() => {
    getPolls()
  }, [])

  const isLg = useMediaQuery({ minWidth: 1200, maxWidth: 1599 })
  const isMd = useMediaQuery({ minWidth: 990, maxWidth: 1199 })
  const isSm = useMediaQuery({ minWidth: 769, maxWidth: 989 })
  const isXs = useMediaQuery({ minWidth: 0, maxWidth: 768 })

  const titleColumns = isSm || isXs ? 8 : 6
  const spaceColumns = 1
  const imageColumns = isSm || isXs ? 5 : 5

  return (
    <AppLayout {...{ history }}>
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid
            container
            item
            xl={titleColumns}
            lg={titleColumns}
            md={titleColumns}
            sm={titleColumns}
            xs={titleColumns}
            alignItems="center"
            justify="center"
          >
            <TitleTxt>{pollsMessages.encuestas}</TitleTxt>
          </Grid>
          {(isLg || isMd) && (
            <Grid
              item
              xl={spaceColumns}
              lg={spaceColumns}
              md={spaceColumns}
              sm={spaceColumns}
              xs={spaceColumns}
            />
          )}
          <Grid
            container
            item
            xl={imageColumns}
            lg={imageColumns}
            md={imageColumns}
            sm={imageColumns}
            xs={imageColumns}
            justify="center"
            alignItems="center"
          >
            <PollImage src={pollsImage} />
          </Grid>
          {(isLg || isMd) && <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />}
        </Grid>
        <PollsContainer>
          <Grid container justify="center" alignItems="stretch">
            {polls.map(({ title, id, isPlaceholder, createdAt }, index) => (
              <Grid container item direction="column" sm={4} xs={12} key={id}>
                {isPlaceholder ? (
                  <PollCard isPlaceholder={true} />
                ) : (
                  <PollCard
                    title={title}
                    createdAt={createdAt}
                    pollId={id}
                    history={history}
                    isMostRecent={index === 0}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </PollsContainer>
      </Container>
    </AppLayout>
  )
}

export default Polls
