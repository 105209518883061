/*global firebase*/

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import firebase from 'firebase'

import AppLayout from '../components/AppLayout'
import PollCard from '../components/PollCard'
import theme from '../resources/theme'

import shapeImage from '../resources/images/shape.svg'
import pollImage from '../resources/images/homeImage.svg'
import { useNotificationContext } from '../context/useNotification'
import messages from '../resources/messages'
import { useMediaQuery } from 'react-responsive'

const Container = styled.div`
  margin: 0 0 136px;
`

const Shape = styled.img`
  width: 100%;
`

const PollImage = styled.img`
  width: 100%;
`

const PollsContainer = styled.div`
  padding: 0 3%;
  ${theme.breakpoints.only('xs')} {
    padding: 0 20%;
  }
  @media all and (max-width: 425px) {
    padding: 0 10%;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`

function HomePage({ history }) {
  const [pollsInfo, setPollsInfo] = useState([])

  const { showNotification } = useNotificationContext()

  const getHomeData = async () => {
    try {
      const docRef = await firebase
        .firestore()
        .collection('polls')
        .orderBy('order', 'desc')
        .limit(3)
        .get()

      const pollsInfo = []

      await docRef.forEach((poll) =>
        pollsInfo.push({ id: poll.id, ...poll.data() })
      )

      setPollsInfo(pollsInfo)
    } catch (e) {
      console.error(e)
      showNotification(e.message || messages.errorGeneral, true)
    }
  }

  useEffect(() => {
    getHomeData()
  }, [])

  const isXl = useMediaQuery({ minWidth: 1599 })

  return (
    <AppLayout {...{ history }}>
      <Container>
        <Grid container align="center">
          <Grid
            container
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            alignItems="flex-start"
          >
            <Shape src={shapeImage} />
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
          <Grid
            container
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            justify="flex-start"
          >
            <PollImage src={pollImage} />
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
        </Grid>
        <PollsContainer>
          <Grid container justify="center" alignItems="stretch">
            {pollsInfo.map(({ title, id, createdAt }, index) => (
              <Grid container item direction="column" sm={4} xs={12} key={id}>
                <PollCard
                  title={title}
                  pollId={id}
                  createdAt={createdAt}
                  history={history}
                  withMarginRight
                  isMostRecent={index === 0}
                />
              </Grid>
            ))}
          </Grid>
        </PollsContainer>
        <ButtonContainer>
          <Button>
            <Link
              variant="button"
              href="/encuestas"
              underline="always"
              color="secondary"
            >
              {messages.homePage.verTodo}
            </Link>
          </Button>
        </ButtonContainer>
      </Container>
    </AppLayout>
  )
}

export default HomePage
