const messages = {
  header: {
    inicio: 'Inicio',
    encuestas: 'Encuestas',
    nosotros: 'Nosotros',
    contacto: 'Contacto',
  },
  footer: {
    copyright: '© Copyright Statu Quo-Advance 2019',
    contacto: 'Contacto:',
    contratacionesAl: 'Contrataciones e información al:',
    contratacionesTel: '(667) 180-4167',
    descargas: 'descargas',
    nosotros: 'Nosotros',
    misionVision: 'Misión y Visión',
    queOfrecemos: '¿Qué Ofrecemos?',
    quienesSomos: '¿Quiénes Somos?',
    valores: 'Valores',
  },
  homePage: {
    verTodo: 'Ver todas las encuestas',
  },
  polls: {
    encuestas: 'Encuestas',
    errorObtenerEncuestas:
      'Error al obtener las encuestas, por favor intenta de nuevo o más tarde',
  },
  pollDetail: {
    descargas: 'descargas',
    encuestaError: 'La encuesta solicitada no está disponible por el momento',
    encuestaNoExiste: 'La encuesta solicitada no existe',
  },
  /** COMPONENTS */
  pollCard: {
    verEncuesta: 'Ver encuesta',
  },
  downloadItem: {
    descargar: 'Descargar',
    descargarEncuesta: 'Descargar Encuesta',
    disponible: 'Disponible:',
  },
  us: {
    nosotros: 'Nosotros',
    mision: 'MISIÓN  ',
    vision: 'VISIÓN  ',
    misionMessage:
      'Somos una agencia de consultoría e inteligencia electoral, única en su tipo, especialista en diagnósticos políticos-electorales que ayuda a nuestros clientes a tomar decisiones sobre los temas de su interés.',
    visionMessage:
      'Posicionarnos como la mejor empresa en su tipo en la República Mexicana.',
    servicios: 'Servicios específicos',
    diagnosticos: 'Diagnósticos',
    diagnosticosMessage:
      'Conocer en qué situación te encuentras de acuerdo a tu intención.',
    acompañamiento: 'Acompañamiento',
    acompañamientoMessage: 'Acompañamiento puntual durante tiempo determinado.',
    discurso: 'Discurso Político',
    discursoMessage:
      'Adecuación de los discursos políticos de acuerdo a la coyuntura.',
    marketing: 'Marketing Político e Imágen Pública',
    marketingMessage:
      'Herramientas para colocar su marca, nombre o imágen en la población objetivo.',
    posicionamiento: 'Posicionamiento Político Electoral',
    posicionamientoMessage: 'Tu nombre será colocado como el más popular.',
    estrategia: 'Estrategia Electoral y Campaña Política',
    estrategiaMessage:
      'Diagnóstico y estrategia de campaña para triunfo político.',
    momentos: 'Momentos de Crisis Política y de Administración Pública',
    momentosMessage:
      'Estrategia de acción y reacción hacia los momentos de impopularidad o rechazo de la ciudadanía hacia una mala toma de decisiones.',
    tecnica: 'Técnica Legislativa',
    tecnicaMessage:
      'Estrategia de acción y reacción hacia los momentos de impopularidad o rechazo de la ciudadanía hacia una mala toma de decisiones.',
  },
  exitPoll: {
    exitPoll: 'Exit Poll',
  },
  errorGeneral:
    'Ocurrió un error en el sistema, favor de intentar de nuevo o más tarde, si el problema persiste favor de comunicarte',
}

export default messages
