import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/SwipeableDrawer'

import Logo from '../resources/images/logo_completo.svg'

export const Container = styled.div`
  height: 48px;
  position: absolute;
  right: 12px;
  padding-top: auto;
`

export const Options = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`

export const Img = styled.img`
  width: 100px;
  margin: 0 auto 12px auto;
  display: block;
`

const MenuMobile = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <IconButton
        color="inherit"
        aria-label="Menu"
        onClick={_ => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={open}
        onClose={_ => setOpen(false)}
        onOpen={_ => setOpen(true)}
        anchor="right"
      >
        <Options>
          <Img src={Logo} alt="statuo quo-advance logo" />
          {children}
        </Options>
      </Drawer>
    </Container>
  )
}

MenuMobile.propTypes = {
  children: PropTypes.node,
}

export default MenuMobile
