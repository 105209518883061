import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

import Header from './Header'
import Footer from './Footer'
import dotsTopImage from '../resources/images/puntos3x5.svg'
import dotsBottomImage from '../resources/images/puntos5x5.svg'

const Container = styled.div`
  align-items: center;
`

const ContentContainer = styled.div`
  position: relative;
  min-height: 80vh;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin: 24px auto 0 auto;
`

const Content = styled.div`
  flex: 1;
  max-width: 100%;
`

const DotsTop = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
`

const DotsBottom = styled(Grid)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const DotsGrid = styled(Grid)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const DotsImage = styled.img`
  width: 100%;
  height: 100%;
`

const WhatOffer = ({ children, history }) => {
  return (
    <Container>
      <Header {...{ history }} />
      <ContentContainer>
        <DotsGrid container>
          <DotsTop item md={3} sm={2} xs={1}>
            <DotsImage src={dotsTopImage} />
          </DotsTop>
          <DotsBottom item xs={2}>
            <DotsImage src={dotsBottomImage} />
          </DotsBottom>
        </DotsGrid>
        <Content>{children}</Content>
      </ContentContainer>
      <Footer />
    </Container>
  )
}

export default WhatOffer
