import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useMediaQuery } from 'react-responsive'

import AppLayout from '../components/AppLayout'
import InfoCard from '../components/InfoCard'
import theme from '../resources/theme'

import usImage from '../resources/images/usImage.svg'
import messages from '../resources/messages'
import { makeStyles } from '@material-ui/core'
import colors from '../resources/colors'
const usMessages = messages.us

const Container = styled.div`
  margin: 0 0 136px;
`

const UsImage = styled.img`
  width: 100%;
`

const TitleTxt = styled.div`
  font-weight: 600;
  line-height 180px;
  font-size: 144px;
  color: #1F1534;

  ${theme.breakpoints.down('lg')} {
    line-height 130px;
    font-size: 110px;
  }
  
  ${theme.breakpoints.down('md')} {
    align-self: center;
    line-height 110px;
    font-size: 80px;
  }
  
  ${theme.breakpoints.down('sm')} {
    align-self: center;
    line-height 100px;
    font-size: 70px;
  }
  
  ${theme.breakpoints.down('xs')} {
    align-self: center;
    line-height 60px;
    font-size: 50px;
  }
`

const MisionVisionContainer = styled.div`
  padding: 0 3%;
  ${theme.breakpoints.only('xs')} {
    padding: 0 10%;
  }
  @media all and (max-width: 425px) {
    padding: 0 15%;
  }
`

const ServicesContainer = styled.div`
  margin-top: 56px;
  ${theme.breakpoints.down('xl')} {
    padding: 0 21%;
  }

  ${theme.breakpoints.down('lg')} {
    padding: 0 14%;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 10%;
  }

  @media all and (max-width: 425px) {
    padding: 0 15%;
  }
`

const BlueLine = styled.div`
  background: ${colors.blueDark};
  height: 1px;
  width: 8%;
  @media all and (max-width: 425px) {
    width: 20%;
  }
  margin-top: -12px;
`

const useStyles = makeStyles({
  servicesTitle: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  serviceTitle: {
    fontWeight: 'bold',
  },
  serviceSubtitle: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
})

const services = [
  {
    title: usMessages.diagnosticos,
    message: usMessages.diagnosticosMessage,
  },
  {
    title: usMessages.acompañamiento,
    message: usMessages.acompañamientoMessage,
  },
  {
    title: usMessages.discurso,
    message: usMessages.discursoMessage,
  },
  {
    title: usMessages.marketing,
    message: usMessages.marketingMessage,
  },
  {
    title: usMessages.posicionamiento,
    message: usMessages.posicionamientoMessage,
  },
  {
    title: usMessages.estrategia,
    message: usMessages.estrategiaMessage,
  },
  {
    title: usMessages.momentos,
    message: usMessages.momentosMessage,
  },
  {
    title: usMessages.tecnica,
    message: usMessages.tecnicaMessage,
  },
]

function Us({ history }) {
  const classes = useStyles()

  const isLg = useMediaQuery({ minWidth: 1200, maxWidth: 1599 })
  const isMd = useMediaQuery({ minWidth: 990, maxWidth: 1199 })
  const isSm = useMediaQuery({ minWidth: 769, maxWidth: 989 })
  const isXs = useMediaQuery({ minWidth: 0, maxWidth: 768 })

  const titleColumns = isSm || isXs ? 8 : 6
  const spaceColumns = 1
  const imageColumns = isSm || isXs ? 5 : 5

  return (
    <AppLayout {...{ history }}>
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid
            container
            item
            xl={titleColumns}
            lg={titleColumns}
            md={titleColumns}
            sm={titleColumns}
            xs={titleColumns}
            alignItems="center"
            justify="center"
          >
            <TitleTxt>{usMessages.nosotros}</TitleTxt>
          </Grid>
          {(isLg || isMd) && (
            <Grid
              item
              xl={spaceColumns}
              lg={spaceColumns}
              md={spaceColumns}
              sm={spaceColumns}
              xs={spaceColumns}
            />
          )}
          <Grid
            container
            item
            xl={imageColumns}
            lg={imageColumns}
            md={imageColumns}
            sm={imageColumns}
            xs={imageColumns}
            justify="center"
            alignItems="center"
          >
            <UsImage src={usImage} />
          </Grid>
          {(isLg || isMd) && <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />}
        </Grid>
        <MisionVisionContainer>
          <Grid container justify="center" alignItems="stretch">
            <Grid container item direction="column" xl={4} md={5} xs={12}>
              <InfoCard
                title={usMessages.mision}
                message={usMessages.misionMessage}
              />
            </Grid>
            <Grid container item direction="column" xl={4} md={5} xs={12}>
              <InfoCard
                title={usMessages.vision}
                message={usMessages.visionMessage}
              />
            </Grid>
          </Grid>
        </MisionVisionContainer>
        <ServicesContainer>
          <Typography variant="h2" className={classes.servicesTitle} paragraph>
            {usMessages.servicios}
          </Typography>
          {services.map(({ title, message }) => (
            <>
              <Typography
                variant="h5"
                className={classes.serviceTitle}
                paragraph
              >
                {title}
              </Typography>
              <BlueLine />
              <Typography
                variant="subtitle2"
                className={classes.serviceSubtitle}
                paragraph
              >
                {message}
              </Typography>
            </>
          ))}
        </ServicesContainer>
      </Container>
    </AppLayout>
  )
}

export default Us
