/*global firebase*/

import React from 'react'
import styled from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Switch, Route } from 'react-router-dom'

import firebase from '../src/utils/firebase'

import HomePage from './screens/HomePage'
import Polls from './screens/Polls'
import PollDetail from './screens/PollDetail'
import Us from './screens/Us'
import ExitPoll from './screens/ExitPoll'

import theme from './resources/theme'
import NotificationProvider from './context/useNotification'

firebase.analytics()

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

function App() {
  return (
    <AppWrapper>
      <MuiThemeProvider theme={theme}>
        <NotificationProvider>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/encuesta" component={PollDetail} />
            <Route exact path="/encuestas" component={Polls} />
            <Route exact path="/nosotros" component={Us} />
            <Route exact path="/exit-poll" component={ExitPoll} />
            <Route path="" component={HomePage} />
          </Switch>
        </NotificationProvider>
      </MuiThemeProvider>
    </AppWrapper>
  )
}

export default App
