import firebase from 'firebase'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyClpO7HNoZQEF3Dx_h7d1yjMNV3Tm3lJxc',
  authDomain: 'statuquo-advance.firebaseapp.com',
  databaseURL: 'https://statuquo-advance.firebaseio.com',
  projectId: 'statuquo-advance',
  storageBucket: 'statuquo-advance.appspot.com',
  messagingSenderId: '344158932979',
  appId: '1:344158932979:web:657ac5e23e56ddf4960c86',
  measurementId: 'G-MRT0DQCSLL',
}

firebase.initializeApp(firebaseConfig)

export default firebase
