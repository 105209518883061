const colors = {
  green: '#29C751',
  greenLight: '#71F0AD',
  red: '#E53436',
  redDark: '#AE2C2D',
  blueLight: '#37C7FF',
  blue: '#0095EB',
  blueDark: '#458DFC',
  blueLightest: '#DCE9FE',
}

export default colors
