import React, { useState, useContext } from 'react'

import Snackbar from '../components/CustomSnackbar'

export const NotificationContext = React.createContext()
export const useNotificationContext = () => useContext(NotificationContext)

const emptyNotification = {
  show: false,
  error: false,
  message: '',
  variant: '',
}

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(emptyNotification)

  const onCloseNotification = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setNotification({ ...notification, show: false })
  }

  const showNotification = (message, error = false, variant = '') => {
    setNotification({ show: true, error, message, variant })
  }

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, onCloseNotification }}
    >
      <Snackbar />
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
