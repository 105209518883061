import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import colors from './colors'

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 990,
      lg: 1200,
      xl: 1600,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['muli'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.blueDark,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 55,
      },
    },
  },
})

theme = responsiveFontSizes(theme)

theme.typography.h4 = {
  fontFamily: 'muli',
  fontSize: '1.8rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  // ['@media (max-width:450px)']: { fontSize: '1rem' },
  // ['@media (max-width:375px)']: { fontSize: '0.8rem' },
}

export default theme
