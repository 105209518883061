import React from 'react'
import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'

import mostRecentIcon from '../resources/images/most-recent-icon.svg'
import theme from '../resources/theme'
import messages from '../resources/messages'
import { spanish } from '../utils/moment'

const pollCardMessages = messages.pollCard
moment.locale('es', spanish)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 32px 24px 32px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    10px 40px 50px rgba(229, 233, 246, 0.4);
  border-radius: 20px;
  justify-content: space-between;
  height: 100%;
  max-width: 800px;
  margin-bottom: 24px;
  margin-right: 5%;
  margin-left: 5%;
  position: relative;

  ${theme.breakpoints.down('lg')} {
    padding: 48px 18px 12px 18px;
  }

  ${theme.breakpoints.only('xs')} {
    padding: 48px 18px 12px 18px;
    margin-right: 0px;
    margin-left: 0px;
  }
`

const Placeholder = styled(Container)`
  box-shadow: 0px 0px 0px;
  border-radius: 0px;
  background: transparent;
`

const Button = styled(MaterialButton)`
  width: 160px;
  height: 45px;
  align-self: flex-end;
  ${theme.breakpoints.down('sm')} {
    align-self: center;
  }
`

const MostRecentImage = styled.img`
  position: absolute;
  left: 0;
  top: 24px;
`

const useStyles = makeStyles({
  titleText: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: '24px',
    marginBottom: '32px',
    color: 'white',
  },
  date: {
    alignSelf: 'flex-start',
  },
})

function PollCard({
  title,
  createdAt,
  pollId,
  withMarginRight,
  isPlaceholder,
  isMostRecent,
  history,
}) {
  const classes = useStyles()

  console.log(createdAt)

  const goToPollDetails = () => {
    history.push(`/encuesta?id=${pollId}`)
  }

  if (isPlaceholder) {
    return <Placeholder />
  }
  return (
    <Container withMarginRight={withMarginRight}>
      {isMostRecent && <MostRecentImage src={mostRecentIcon} />}
      <Typography variant="h4" className={classes.titleText}>
        {title}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={goToPollDetails}
      >
        {pollCardMessages.verEncuesta}
      </Button>
      <Typography>{moment(createdAt).format('DD MMMM YYYY')}</Typography>
    </Container>
  )
}

export default PollCard
