/*global firebase*/

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import queryString from 'query-string'
import moment from 'moment'
import firebase from 'firebase'

import AppLayout from '../components/AppLayout'
import theme from '../resources/theme'

import sqMessages from '../resources/messages'
import { Typography } from '@material-ui/core'
import DownloadItem from '../components/DownloadItem'
import { useNotificationContext } from '../context/useNotification'
const messages = sqMessages.pollDetail

const Container = styled.div`
  margin: 0 0 136px;
  padding: 0 5%;
`

const Title = styled.div`
  margin-top: 148px;
  margin-bottom: 60px;

  ${theme.breakpoints.down('xs')} {
    margin-top: 60px;
    margin-bottom: 30px;
  }
`

const Image = styled.img`
  width: 100%;
  margin-bottom: 24px;
`

const Date = styled.div`
  margin-bottom: 24px;
`

function PollDetail({ history }) {
  const [pollData, setPollData] = useState({})
  const { showNotification } = useNotificationContext()

  const { id: pollId } = queryString.parse(history.location.search)

  const getPollData = async () => {
    try {
      const db = firebase.firestore()
      const pollRef = db.collection('polls').doc(pollId)
      const pollDoc = await pollRef.get()

      if (!pollDoc.exists) {
        throw new Error(messages.encuestaNoExiste)
      }

      const pollData = pollDoc.data()
      if (!pollData.active) {
        throw new Error(messages.encuestaError)
      }
      if (!pollData.multipleFiles && pollData.fileId) {
        const fileRef = db.collection('files').doc(pollData.fileId)
        const fileDoc = await fileRef.get()
        if (fileDoc.exists) {
          const { counter } = fileDoc.data()
          setPollData({ ...pollData, downloadCounter: counter })
          return
        }
      } else {
        setPollData(pollData)
      }
    } catch (e) {
      showNotification(e.message || 'error', true)
      history.replace('/')
    }
  }

  useEffect(() => {
    getPollData()
  }, [])

  const {
    title,
    imageUrl,
    fileId,
    createdAt,
    downloadCounter,
    multipleFiles: hasMultipleFiles,
    files,
  } = pollData

  const multipleFilesElements =
    files &&
    files.map(({ fileId, title, availableAt }, index) => (
      <DownloadItem key={index} {...{ fileId, title, availableAt }} />
    ))

  return (
    <AppLayout {...{ history }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={12}>
            <Title>
              <Typography variant="h4" align="left">
                {title}
              </Typography>
            </Title>
          </Grid>
          <Grid container justify={'center'}>
            <Grid item md={hasMultipleFiles ? 6 : 12} sm={10} xs={12}>
              <Image alt={title} src={imageUrl} />
              <Date>
                <Typography variant="h4" align="center">
                  {moment(createdAt).format('DD MMMM YYYY')}
                </Typography>
              </Date>
            </Grid>
            {hasMultipleFiles ? (
              <Grid
                container
                alignContent="flex-start"
                alignItems="flex-end"
                xl={5}
                md={6}
                xs={12}
              >
                {multipleFilesElements}
              </Grid>
            ) : (
              <>
                <Grid container xl={4} md={6} xs={10}>
                  {fileId ? (
                    <DownloadItem {...{ fileId }} isSingleItem />
                  ) : (
                    <Grid container justify="center">
                      <Typography variant="h6">
                        Descarga disponible próximamente
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {downloadCounter >= 1 && (
                  <Grid container justify="center">
                    <Typography variant="body2">{`${downloadCounter.toLocaleString()} ${
                      messages.descargas
                    }`}</Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  )
}

export default PollDetail
