import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import theme from '../resources/theme'
import colors from '../resources/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    10px 40px 50px rgba(229, 233, 246, 0.4);
  border-radius: 20px;
  justify-content: space-between;
  height: 100%;
  max-width: 800px;
  margin-bottom: 24px;
  margin-right: 7.5%;
  margin-left: 7.5%;
  position: relative;

  ${theme.breakpoints.down('lg')} {
    padding: 24px;
  }

  ${theme.breakpoints.only('xs')} {
    margin-right: 0px;
    margin-left: 0px;
  }
`

const Placeholder = styled(Container)`
  box-shadow: 0px 0px 0px;
  border-radius: 0px;
  background: transparent;
`

const TitleContainer = styled.div`
  display: flex;
`

const TitlePlaceholder = styled.div`
  flex: 1;
`

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: colors.blueDark,
    borderBottomColor: colors.blueDark,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    whiteSpace: 'pre',
  },
  message: {
    marginTop: '12px',
    fontWeight: 'bold',
    flex: 1,
  },
})

function InfoCard({ title, message, isPlaceholder = false }) {
  const classes = useStyles()

  if (isPlaceholder) {
    return <Placeholder />
  }
  return (
    <Container>
      <TitleContainer>
        <span>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </span>
        <TitlePlaceholder />
      </TitleContainer>
      <Typography variant="h6" className={classes.message}>
        {message}
      </Typography>
    </Container>
  )
}

export default InfoCard
