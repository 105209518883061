/*global firebase*/

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { useMediaQuery } from 'react-responsive'
import firebase from 'firebase'
import _ from 'lodash'
import { Chart } from 'react-charts'

import AppLayout from '../components/AppLayout'
import InfoCard from '../components/InfoCard'
import theme from '../resources/theme'

import pollsImage from '../resources/images/pollsImage.svg'
import messages from '../resources/messages'
import { makeStyles } from '@material-ui/core'
import colors from '../resources/colors'
const exitPollMessages = messages.exitPoll

const Container = styled.div`
  margin: 0 0 136px;
`

const UsImage = styled.img`
  width: 100%;
`

const TitleTxt = styled.div`
  font-weight: 600;
  line-height 180px;
  font-size: 144px;
  color: #1F1534;

  ${theme.breakpoints.down('lg')} {
    line-height 130px;
    font-size: 110px;
  }
  
  ${theme.breakpoints.down('md')} {
    align-self: center;
    line-height 110px;
    font-size: 80px;
  }
  
  ${theme.breakpoints.down('sm')} {
    align-self: center;
    line-height 100px;
    font-size: 70px;
  }
  
  ${theme.breakpoints.down('xs')} {
    align-self: center;
    line-height 60px;
    font-size: 50px;
  }
`

const MisionVisionContainer = styled.div`
  padding: 0 3%;
  ${theme.breakpoints.only('xs')} {
    padding: 0 10%;
  }
  @media all and (max-width: 425px) {
    padding: 0 15%;
  }
`

const ServicesContainer = styled.div`
  margin-top: 56px;
  ${theme.breakpoints.down('xl')} {
    padding: 0 21%;
  }

  ${theme.breakpoints.down('lg')} {
    padding: 0 14%;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 10%;
  }

  @media all and (max-width: 425px) {
    padding: 0 15%;
  }
`

const BlueLine = styled.div`
  background: ${colors.blueDark};
  height: 1px;
  width: 8%;
  @media all and (max-width: 425px) {
    width: 20%;
  }
  margin-top: -12px;
`

const useStyles = makeStyles({
  servicesTitle: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  serviceTitle: {
    fontWeight: 'bold',
  },
  serviceSubtitle: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
})

const ExitPoll = ({ history }) => {
  const classes = useStyles()

  const isLg = useMediaQuery({ minWidth: 1200, maxWidth: 1599 })
  const isMd = useMediaQuery({ minWidth: 990, maxWidth: 1199 })
  const isSm = useMediaQuery({ minWidth: 769, maxWidth: 989 })
  const isXs = useMediaQuery({ minWidth: 0, maxWidth: 768 })

  const titleColumns = isSm || isXs ? 8 : 6
  const spaceColumns = 1
  const imageColumns = isSm || isXs ? 5 : 5

  const [polls, setPolls] = useState({})

  const [muniSelected, setMunSelected] = useState('navolato')
  const [sindSelected, setSindSelected] = useState(-1)
  const [distSelected, setDistSelected] = useState(-1)
  const [sectSelected, setSectSelected] = useState(-1)
  const dbRef = useRef(null)

  const [presResults, setPresResults] = useState({})
  const [presMunResults, setPresMunResults] = useState({})
  const [senResults, setSenResults] = useState({})
  const [edadResults, setEdadResults] = useState({})

  const getPolls = async () => {
    try {
      let uri = 'exitPoll/'
      if (muniSelected !== -1) {
        uri += `${muniSelected}/`
        if (sindSelected !== -1) {
          uri += `${sindSelected}`
        }
      }
      dbRef.current = firebase.database().ref(uri)
      dbRef.current.on('value', (snapshot) => {
        const data = snapshot.val()
        setPolls(data)
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPolls()
  }, [muniSelected, sindSelected, distSelected, sectSelected])

  useEffect(() => {
    setResults()
  }, [polls])

  const setResults = () => {
    const getResults = (current, poll, key) => {
      if (poll && poll[key]) {
        _.forEach(poll[key], (value, k) => {
          const currentCount = current[k] ? current[k] || 0 : 0
          current[k] = currentCount + Number(value)
        })
      }

      return current
    }

    const convertRes = (res) => {
      const newObj = {}
      _.forEach(res, (value, key) => {
        newObj[key] = { count: value }
      })
      return newObj
    }

    const getSindResults = (poll) => {
      setPresResults(convertRes(getResults({}, poll, 'presidente')))
      setPresMunResults(convertRes(getResults({}, poll, 'presidenteMunicipal')))
      setSenResults(convertRes(getResults({}, poll, 'senado')))
      setEdadResults(convertRes(getResults({}, poll, 'edad')))
    }

    const getMunicipalityPolls = (polls) => {
      let presRes = {}
      let presMunRes = {}
      let senRes = {}
      let edadRes = {}
      _.forEach(polls, (poll) => {
        presRes = getResults(presRes, poll, 'presidente')
        presMunRes = getResults(presMunRes, poll, 'presidenteMunicipal')
        senRes = getResults(senRes, poll, 'senado')
        edadRes = getResults(edadRes, poll, 'edad')
      })
      setPresResults(convertRes(presRes))
      setPresMunResults(convertRes(presMunRes))
      setSenResults(convertRes(senRes))
      setEdadResults(convertRes(edadRes))
    }

    const getStatePolls = (polls) => {
      _.forEach(polls, (poll) => {
        getMunicipalityPolls(poll)
      })
    }

    if (sindSelected !== -1) {
      getSindResults(polls)
    } else if (muniSelected !== -1) {
      getMunicipalityPolls(polls)
    } else {
      getStatePolls(polls)
    }
  }

  const series = React.useMemo(
    () => ({
      type: 'bar',
    }),
    []
  )

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: 'ordinal',
        position: 'bottom',
        showGrid: true,
        showTicks: true,
      },
      {
        position: 'left',
        type: 'linear',
        hardMin: 0,
      },
    ],
    []
  )

  const presData = [
    {
      label: 'PRI-PAN-PRD',
      data: [],
      color: '#0C328A',
    },
    {
      label: 'MORENA-PT-PV',
      data: [],
      color: '#571511',
    },
    {
      label: 'MC',
      data: [],
      color: '#E98531',
    },
    {
      label: 'Nulo',
      data: [],
      color: '#000',
    },
  ]

  const presDataDict = _.keyBy(presData, 'label')
  const setDataPres = (label, results) => {
    _.forOwn(results, (value, key) => {
      if (key !== 'INVALID') {
        presDataDict[key].data.push({ x: label, y: value.count })
      }
    })
  }

  const presDataMun = [
    {
      label: 'PRI-PAN-PRD-PAS',
      data: [],
      color: '#0C328A',
    },
    {
      label: 'MORENA',
      data: [],
      color: '#571511',
    },
    {
      label: 'MC',
      data: [],
      color: '#E98531',
    },
    {
      label: 'PT',
      data: [],
      color: '#C2372A',
    },
    {
      label: 'PV',
      data: [],
      color: '#6CB555',
    },
    {
      label: 'ES',
      data: [],
      color: '#512E74',
    },
    {
      label: 'Nulo',
      data: [],
      color: '#000',
    },
  ]

  const presMunDataDict = _.keyBy(presDataMun, 'label')
  const setDataPresMun = (label, results) => {
    _.forOwn(results, (value, key) => {
      if (key !== 'INVALID') {
        presMunDataDict[key].data.push({ x: label, y: value.count })
      }
    })
  }

  const senData = [
    {
      label: 'PRI-PAN-PRD',
      data: [],
      color: '#0C328A',
    },
    {
      label: 'MORENA',
      data: [],
      color: '#571511',
    },
    {
      label: 'MC',
      data: [],
      color: '#E98531',
    },
    {
      label: 'PT',
      data: [],
      color: '#C2372A',
    },
    {
      label: 'PV',
      data: [],
      color: '#6CB555',
    },
    {
      label: 'Nulo',
      data: [],
      color: '#000',
    },
  ]

  const senDataDict = _.keyBy(senData, 'label')
  const setDataSen = (label, results) => {
    _.forOwn(results, (value, key) => {
      if (key !== 'INVALID') {
        senDataDict[key].data.push({ x: label, y: value.count })
      }
    })
  }

  const edadData = [
    {
      label: 'f18',
      data: [],
      color: '#FFC0CB',
    },
    {
      label: 'f25',
      data: [],
      color: '#FF69B4',
    },
    {
      label: 'f35',
      data: [],
      color: '#FF1493',
    },
    {
      label: 'f45',
      data: [],
      color: '#C71585',
    },
    {
      label: 'f55',
      data: [],
      color: '#8B008B',
    },
    {
      label: 'm18',
      data: [],
      color: '#87CEEB',
    },
    {
      label: 'm25',
      data: [],
      color: '#1E90FF',
    },
    {
      label: 'm35',
      data: [],
      color: '#4169E1',
    },
    {
      label: 'm45',
      data: [],
      color: '#0000CD',
    },
    {
      label: 'm55',
      data: [],
      color: '#00008B',
    },
  ]

  const edadDataDict = _.keyBy(edadData, 'label')
  const setDataEdad = (label, results) => {
    _.forOwn(results, (value, key) => {
      if (key !== 'INVALID') {
        edadDataDict[key].data.push({ x: label, y: value.count })
      }
    })
  }

  setDataPres('Presidente', presResults)
  setDataPresMun('Presidente Municipal', presMunResults)
  setDataSen('Senador', senResults)
  setDataEdad('Edad-Sexo', edadResults)

  const isMobile = isSm || isXs

  const chartStyle = {
    width: isMobile ? '100%' : '25%',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: isMobile ? 50 : 0,
  }

  return (
    <AppLayout {...{ history }}>
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginTop: 40,
              marginBottom: 140,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 400,
              alignSelf: 'center',
            }}
          >
            <InputLabel id="municipio">Municipio</InputLabel>
            <Select
              labelId="municipio"
              value={muniSelected}
              onChange={(evt) => setMunSelected(evt.target.value)}
              variant="outlined"
              style={{ marginBottom: 16 }}
            >
              {/* <MenuItem value={-1}>TODOS</MenuItem> */}
              <MenuItem value={'navolato'}>Navolato</MenuItem>
            </Select>
            <InputLabel id="sindicatura">Sindicatura</InputLabel>
            <Select
              labelId="sindicatura"
              value={sindSelected}
              onChange={(evt) => {
                if (dbRef.current) dbRef.current.off('value')
                setSindSelected(evt.target.value)
              }}
              variant="outlined"
            >
              <MenuItem value={-1}>TODOS</MenuItem>
              <MenuItem value={1}>Navolato Centro</MenuItem>
              <MenuItem value={2}>Altata</MenuItem>
              <MenuItem value={3}>Bachimeto</MenuItem>
              <MenuItem value={4}>El Tigre</MenuItem>
              <MenuItem value={5}>San Pedro</MenuItem>
              <MenuItem value={6}>Sataya</MenuItem>
              <MenuItem value={7}>Villa Juarez</MenuItem>
              <MenuItem value={8}>La Palma</MenuItem>
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div style={chartStyle}>
              <Chart data={presData} series={series} axes={axes} tooltip />
              <p>Presidente de la República</p>
            </div>
            <div style={chartStyle}>
              <Chart data={presDataMun} series={series} axes={axes} tooltip />
              <p>Presidente Municipal</p>
            </div>
            <div style={chartStyle}>
              <Chart data={senData} series={series} axes={axes} tooltip />
              <p>Senado</p>
            </div>
            <div style={chartStyle}>
              <Chart data={edadData} series={series} axes={axes} tooltip />
              <p>Edad y Sexo</p>
            </div>
          </div>
        </div>
      </Container>
    </AppLayout>
  )
}

export default ExitPoll
